import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function App() {
  const form = useRef();
  let [show, setShow] = useState(false)
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_g7b6qzc', 'template_lo03row', form.current, 'n4mRHHvtYePB06bAE')
      .then((result) => {
          console.log(result.text);
          form.current.user_email.value = '';
          setShow(true);
      }, (error) => {
          console.log(error.text);
          setShow(false);
      });
  };

  return (
    <main className="container">
      <section className="row">
        <article className='col vh-100 d-flex flex-column justify-content-center align-items-center'>
          <div className='text-center'>
            <h1>Niovis</h1>
            <p>Educaci&oacute;n renovada para el futuro.</p>
          </div>
          <form  ref={form} onSubmit={sendEmail}>
              <div className="my-5 text-center">
                <p className='warning'>Para recibir actualizaciones, introduce tu correo electrónico.</p>
                <input type="email" className="form-control" id="email" name="user_email" aria-describedby="emailHelp" placeholder='Correo electrónico' required/>
                <div id="emailHelp" className="form-text  mb-2">No vamos a compartir tu correo electrónico con nadie.</div>
                {
                  show ? <div className="alert alert-success" role="alert">
                    ¡Muchas gracias!
                  </div> : null 
                }
                <input type="submit" value="ENVIAR" className='mt-3' />
              </div>
            </form>
        </article>
      </section>
    </main>
  );
}

export default App;
